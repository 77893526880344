import tingle from "tingle";
import lazyVideoHelper from "../../../../components/bhg/lazyVideos-helper";

bcl.modal = {
  props: {
    cssSelector: "c-modal",
    cssSelectorInjectContent: "c-modal-inject",
    modalType: ["src", "inject"],
    hasPriceButton: false,
    lang: "",
    cmsStore: "",
    modalDefaultProperties: {
      closeMethods: ["overlay", "button", "escape"],
      closeLabel: "< Go back",
      onOpen: function () {
        const close = bcl.modal.props.$modal.modal.querySelector(".tingle-modal__close");

        if (close) {
          bcl.modal.props.$modal.modalBoxContent.appendChild(close);
        }

        document.dispatchEvent(new CustomEvent("modal-open", { detail: this.modalBox }));
        bcl.u.computeScript(this.modalBoxContent);

        setTimeout(() => {
          if (bcl.modal.props.$modal.modal.querySelector(".video-youtube")) {
            lazyVideoHelper.modalCheckEnableVideo(bcl.modal.props.$modal.modal.querySelector(".video-youtube"));
          } else if (bcl.modal.props.$modal.modal.querySelector(".video-vimeo")) {
            lazyVideoHelper.modalCheckEnableVideo(bcl.modal.props.$modal.modal.querySelector(".video-vimeo"));
          } else if (bcl.modal.props.$modal.modal.querySelector(".video-generic")) {
            lazyVideoHelper.modalCheckEnableVideo(bcl.modal.props.$modal.modal.querySelector(".video-generic"));
          }
        }, 0);
        //PLASOFT-21696 check this settimeout TODO Manuel
        if (bcl.modal.props?.$modal?.modalBoxContent) {
          bcl.modal.buildmodalHtmlStructure();
          setTimeout(() => {
            bcl.modal.trapFocus();
          }, 100);
        }
      },
      onClose: function () {
        document.dispatchEvent(new CustomEvent("modal-close", { detail: this.modalBox }));
        bcl.modal.focusToCurrentModalElement();
      },
    },
    $modal: null,
    $currentElement: null,
    list: {},
    focusableSelector: `
      button, 
      [href], 
      input:not([type="hidden"]), 
      select, 
      textarea, 
      [tabindex]:not([tabindex="-1"]), 
      [role="button"], 
      [role="link"], 
      [role="checkbox"], 
      [role="radio"], 
      [role="switch"], 
      [role="menuitem"], 
      [role="option"]`.trim(),
    areLabelByTitleSelector: "h1,h2,h3,h4,h5,h6",
  },

  preInit: function () {
    bcl.u.docReady(bcl.modal.init);
  },

  init: function (baseDom) {
    const baseFind = baseDom ? baseDom : document,
      modals = baseFind.querySelectorAll("button." + bcl.modal.props.cssSelector);
    let author = false;

    if (baseFind.querySelector("html")) {
      bcl.modal.props.lang = baseFind.querySelector("html").getAttribute("lang");
    }
    if (bcl.u.containsClass(document.body, "isAuthor")) {
      author = true;
    }

    bcl.modal.initUrlWrapperRedirectorUrl();
    for (const $button of modals) {
      if ($button.closest(".firstViewLink-JS")) {
        return;
      }
      $button.addEventListener("click", bcl.modal.initModal);
      if (author && !$button.dataset.iframeHtml) {
        if ($button.dataset.href.includes("?")) {
          $button.setAttribute("data-href", $button.dataset.href + "&wcmmode=disabled");
        } else {
          $button.setAttribute("data-href", $button.dataset.href + "?wcmmode=disabled");
        }
      }
    }
    const $body = baseFind.querySelector("body");
    if (!author && $body && $body.dataset.genericAlert && !bcl.cookie.get(bcl.modal.props.lang + "genericAlert") && !$body.dataset.noShowGenericAlert) {
      const href = $body.dataset.genericAlert;

      window.addEventListener("preInitLoaded", function () {
        setTimeout(function () {
          bcl.modal.initModal(null, href);
        }, 2000);
      });
    }
  },

  initUrlWrapperRedirectorUrl: function () {
    const urlwrapperredirector = document.querySelectorAll(".fastbooking-JS");
    if (urlwrapperredirector && urlwrapperredirector.length > 0) {
      const urlValue = urlwrapperredirector[0].dataset.urlwrapperredirector;
      bcl.modal.props.cmsStore = urlValue;
    }
  },
  checkIfIsCarrouselModalOfHotelRoom: function (event) {
    let isRoomModal = false;
    if (event.target?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode != undefined) {
      if (!bcl.u.containsClass(event.target.parentNode.parentNode.parentNode.parentNode.parentNode, "c-hotel-room-list__content-wrapper")) {
        isRoomModal = true;
      }
    }
    return isRoomModal;
  },

  initModal: function (event, href, customInject) {
    if (event) {
      event.preventDefault();
    }

    if (!bcl.modal.checkIfIsCarrouselModalOfHotelRoom(event)) {
      bcl.modal.close();
    }

    let modalType;
    if (this.dataset) {
      modalType = this.dataset.modaltype;
      bcl.modal.props.hasPriceButton = this.dataset.haspricebutton === "true";
    }

    if (modalType) {
      switch (modalType) {
        case bcl.modal.props.modalType[0]:
          bcl.modal.initInject(this);
          break;
        case bcl.modal.props.modalType[1]:
          bcl.modal.initSibling(this);
          break;
      }
    } else {
      const url = href ? href : this.dataset.href;

      if (url) {
        if (customInject) {
          customInject(event, this, href);
        } else {
          bcl.modal.initInject(this, href);
        }
      }
    }
  },

  initSibling: function ($el) {
    const url = $el.dataset.href,
      modal = bcl.modal.props.list[url],
      $modal = modal && modal.modalBoxContent.querySelector("." + bcl.modal.props.cssSelector);

    if (modal && $modal && !$modal.dataset.modalrigth) {
      const parent = $el.parentElement;
      if (parent && parent.classList.contains("c-video")) {
        const injectContent = parent.querySelector("." + bcl.modal.props.cssSelectorInjectContent);
        if (injectContent && injectContent.querySelector(".c-video-responsive")) {
          bcl.modal.props.$modal.setContent(injectContent.cloneNode(true));
          modal.open();
          return;
        }
      }
      bcl.modal.props.$modal = modal;
      modal.open();
    } else {
      const injectContent = $el.parentNode.getElementsByClassName(bcl.modal.props.cssSelectorInjectContent);

      if (injectContent && injectContent.length > 0) {
        const injectContentCloned = injectContent[0].cloneNode(true);
        bcl.modal.openModal(injectContentCloned, null, $el);
      }
    }
  },

  initInject: function ($el, href, customCallback) {
    const url = href ? href : $el.dataset.href,
      modal = bcl.modal.props.list[url],
      $modal = modal && modal.modalBoxContent.querySelector("." + bcl.modal.props.cssSelector);

    if (modal && $modal && !$modal.dataset.modalrigth) {
      if ($el.dataset.index) {
        const swiperCont = $modal.querySelector(".cmp-bhg-swiper-js");
        if (swiperCont && swiperCont.dataset?.index && swiperCont.dataset.index != $el.dataset.index) {
          swiperCont.setAttribute("data-index", $el.dataset.index);
          swiperCont?.swiper.slideTo($el.dataset.index);
        }
      }
      bcl.modal.props.$modal = modal;
      modal.open();
    } else {
      const $param = href ? href : $el;
      if ($el.dataset.iframeHtml) {
        bcl.modal.initInjectIframe($el);
      } else {
        customCallback ? bcl.ajax.getRequest(null, url, customCallback, $param) : bcl.ajax.getRequest(null, url, bcl.modal.initInjectCallback, $param);
      }
    }
  },

  initInjectCallback: function (response, $el) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(response.responseText, "text/html");
    const modalElement = doc.querySelector(".c-modal");
    const modalText = modalElement ? modalElement.outerHTML : response.responseText;

    bcl.modal.openModal(modalText, null, $el);
    const $injectedModal = document.querySelector(".tingle-modal.tingle-modal--visible .c-modal");
    if (bcl.s?.translateOnTheFly?.hasToTranslate()) {
      bcl.s.translateOnTheFly?.handleNodeTranslation($injectedModal);
    }
    bcl.u.loadComponentsForInjectedHtml($injectedModal);
  },

  initInjectIframe: function ($el) {
    bcl.modal.openModal($el.dataset.href, null, $el);
    const $injectedModal = document.querySelector(".tingle-modal.tingle-modal--visible .c-modal, .tingle-modal.tingle-modal--visible .tingle-modal-box__content");
    const $iframe = $injectedModal.querySelector("iframe");
    if ($iframe) {
      $iframe.addEventListener("load", function () {
        let streak = 0;
        const interval = setInterval(function () {
          if ($injectedModal.clientHeight == $iframe.clientHeight) {
            streak++;
          } else {
            $injectedModal.style.height = ($iframe.getAttribute("height") || $injectedModal.clientHeight) + "px";
            streak = 0;
          }
          if (streak === 10) {
            clearInterval(interval);
          }
        }, 500);
      });
    }
  },
  appendContent: function (content, properties) {
    const titleAssetObject = document.createElement("div");
    if (typeof content === "object") {
      titleAssetObject.appendChild(content);
    } else {
      titleAssetObject.innerHTML = content;
    }

    const titleAsset = titleAssetObject.querySelector("[data-close]");
    if (titleAsset) {
      const closeLabel = titleAsset.dataset.close;
      if (closeLabel) {
        properties.closeLabel = closeLabel;
      }
    }
  },
  focusToCurrentModalElement: function () {
    if (bcl.modal.props.$currentElement) {
      bcl.modal.props.$currentElement.focus();
      if (bcl.s.tabs?.props?.$containerActive && bcl.s.tabs.props.$containerActive.contains(bcl.modal.props.$currentElement)) {
        bcl.s.tabs.props.closedModalFromTab = true;
      }
      bcl.modal.props.$currentElement = null;
    }
  },
  openModal: function (content, properties, $el) {
    bcl.modal.props.$currentElement = $el;
    if (!properties) {
      bcl.modal.props.modalDefaultProperties.onClose = function () {
        document.dispatchEvent(new CustomEvent("modal-close", { detail: this.modalBox }));
        bcl.cookie.set(bcl.modal.props.lang + "genericAlert", "true", { path: "/" });
        this.modalBox?.closest(".tingle-modal")?.remove();
        bcl.modal.props.list = {};
        bcl.modal.focusToCurrentModalElement();
      };
      properties = bcl.modal.props.modalDefaultProperties;
    }

    bcl.modal.appendContent(content, properties);

    if ($el.dataset.beforeinject) {
      const callback = $el.dataset.beforeinject;
      content = bcl.u.executeFunctionByName(callback, window, $el, content);
    }

    bcl.modal.props.$modal = new tingle.modal(properties);
    bcl.modal.props.$modal.setContent(content);

    const $modal = bcl.modal.props.$modal.modalBoxContent.querySelector("." + bcl.modal.props.cssSelector);

    if ($modal) {
      if ($el?.dataset?.modalSelector) {
        $modal.closest(".tingle-modal")?.classList?.add($el.dataset.modalSelector);
      }
      //call again to load MybTriggerBanner because on Modal does no exists
      if ($modal.getElementsByClassName("cmp-bhg-myb-trigger-banner-carousel-JS").length > 0) {
        bcl.u.loadComponentByName("mybTriggerBanner");
      }

      const $parentModal = bcl.modal.props.$modal.modal,
        classMobile = $modal.dataset.modalrigth;

      bcl.u.removeClass($parentModal, classMobile);

      if (bcl.u.mobile.isMobile()) {
        bcl.u.addClass($parentModal, classMobile);
      }

      if ($modal.dataset.addclass) {
        const classList = $modal.dataset.addclass.split(".");
        for (const classItem of classList) {
          bcl.u.addClass(bcl.modal.props.$modal.modalBoxContent, classItem);
        }
        if (bcl.modal.props.$modal.modalBoxContent.classList.contains("rounded")) {
          bcl.u.addClass(bcl.modal.props.$modal.modal, "rounded");
        }
        if (bcl.modal.props.$modal.modalBoxContent.classList.contains("both-rounded")) {
          bcl.u.addClass(bcl.modal.props.$modal.modal, "both-rounded");
        }

        $modal.dataset.addclass = "";
      }

      if (bcl.modal.props.hasPriceButton) {
        bcl.modal.computePriceButton($el);
      }
    }

    if (typeof $el === "object") {
      bcl.modal.props.list[$el.dataset.href] = bcl.modal.props.$modal;
    }

    bcl.modal.props.$modal.modalCloseBtnIcon.innerHTML =
      // eslint-disable-next-line @stylistic/js/max-len
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path fill="#384044" fill-rule="nonzero" d="M45.1385 42.6451L27.2089 24.7156 45.3571 6.5673c.321-.3209.5185-.7617.5185-1.2484 0-.9734-.79-1.7633-1.7634-1.7633-.4867 0-.9275.1974-1.2484.5184L24.7156 22.2222 6.5673 4.074c-.3209-.321-.7617-.5184-1.2484-.5184-.9734 0-1.7633.79-1.7633 1.7633 0 .4867.1974.9275.5184 1.2449l18.1482 18.1518L4.074 42.8638c-.321.321-.5184.7617-.5184 1.2484 0 .9734.79 1.7634 1.7633 1.7634.4867 0 .9275-.1975 1.2484-.5185L24.7156 27.209 42.645 45.1385l.0423-.0423c.3174.4584.8253.7794 1.4248.7794.9734 0 1.7634-.79 1.7634-1.7634 0-.603-.321-1.1074-.7794-1.4248l.0423-.0423z"/></svg>';

    bcl.modal.props.$modal.open();
    //add cms_store_param on register link
    //https://redirector.barcelo.com/?start=SU&am=True
    setTimeout(function () {
      if ($modal) {
        bcl.modal.addCmsStoreParam($modal);
      }
    }, 1000);
  },
  trapFocus: function () {
    const focusableElements = bcl.modal.props?.$modal?.modalBoxContent?.querySelectorAll(bcl.modal.props.focusableSelector);
    if (focusableElements != null && focusableElements.length > 0) {
      const firstFocusableElement = focusableElements[0];
      const lastFocusableElement = focusableElements[focusableElements.length - 1];
      if (firstFocusableElement) {
        firstFocusableElement.focus();
      }
      bcl.modal.props.$modal.modalBoxContent.addEventListener("keydown", (event) => {
        if (event.key === "Tab") {
          if (event.shiftKey) {
            if (document.activeElement === firstFocusableElement) {
              event.preventDefault();
              lastFocusableElement.focus();
            }
          } else {
            if (document.activeElement === lastFocusableElement) {
              event.preventDefault();
              firstFocusableElement.focus();
            }
          }
        }
      });
    }
  },
  buildmodalHtmlStructure: function () {
    const modalBoxContent = bcl.modal.props?.$modal?.modalBoxContent;
    if (modalBoxContent) {
      modalBoxContent.setAttribute("role", "dialog");
      modalBoxContent.setAttribute("aria-modal", "true");
      const ariaLabelbyElement = modalBoxContent.querySelector(bcl.modal.props.areLabelByTitleSelector);
      if (ariaLabelbyElement) {
        let modalAriaLabelbyId = ariaLabelbyElement.getAttribute("id");
        if (!modalAriaLabelbyId) {
          ariaLabelbyElement.setAttribute("id", "modalAriaLabelbyId");
          modalAriaLabelbyId = "modalAriaLabelbyId";
        }
        modalBoxContent.setAttribute("aria-labelledby", modalAriaLabelbyId);
      }
    }
  },
  addCmsStoreParam: function ($modal) {
    const allLinks = $modal.querySelectorAll("a");

    if (!allLinks || allLinks.length <= 0) {
      return;
    }

    bcl.u.forEach(allLinks, function (linkItem) {
      let href = linkItem.getAttribute("href");
      if (href && href.startsWith("https://redirector.barcelo.com")) {
        const currentCmsStore = bcl.u.getUrlParam(bcl.modal.props.cmsStore, "cms_store");
        if (currentCmsStore) {
          const allParamsOnUrl = bcl.u.getAllUrlParamsFromUrl(href);
          if (Object.keys(allParamsOnUrl).length > 0) {
            href = href + "&cms_store=" + currentCmsStore;
          } else {
            href = href + "?cms_store=" + currentCmsStore;
          }
          linkItem.setAttribute("href", href);
        }
      }
    });
  },

  computePriceButton: function ($el) {
    if ($el && $el.closest("article") && $el.closest("article").querySelector(".card-hotel-price-JS")) {
      const priceContainer = $el.closest("article").querySelector(".card-hotel-price-JS");
      const clone = priceContainer.cloneNode(true);
      bcl.modal.props.$modal.modal.querySelector(".booking-price-modal-JS").after(clone);
      const $button = bcl.modal.props.$modal.modal.querySelector(".booking-button-modal-JS");
      $button.addEventListener("click", bcl.modal.closeAndTriggerBooking);
    }
  },

  closeAndTriggerBooking: function () {
    bcl.modal.close();
    if (this.dataset && this.dataset.hotel) {
      const hotelId = this.dataset.hotel;
      const $button = document.querySelector(".booking-button-JS[data-hotel='" + hotelId + "']");
      if ($button) {
        $button.click();
      }
    }
  },

  close: function () {
    if (!bcl.modal.props.$modal) {
      return;
    }
    bcl.modal.props.$modal.setContent("");
    bcl.modal.props.$modal.close();
  },
};

bcl.preInit.push(bcl.modal.preInit);
