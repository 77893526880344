import { megaMenuCommonProps } from "barcelo-components/structure/header/js/commonHeaderProps";

bcl.s.tabs = {
  props: {
    containerTabsSelector: "container-tabs-JS",
    tabsSelector: "tabs-JS",
    tabSelector: "tab-JS",
    containerContentSelector: "container-contents-JS",
    containerCountryLangClass: ".country-lang",
    contentSelector: "content-JS",
    caretSelector: "caret-JS",
    classClose: "close-JS",
    classNoClose: "no-close-JS",
    classActive: "mod--active",
    classHidden: "mod--hide",
    classShowUpper: "mod--showUp",
    classItemMultiple: "lastItem",
    containerTabAttr: "data-containertab",
    languageDropdownJS: ".language-dropdown-JS:not(.hidden)",
    languageCurrentJS: ".language-current-JS",
    disabled: "disabled",
    rowSpan: ".c-header__nav-container .c-megamenu__menu-utils-item-anchor.language-selection",
    spanRowIcon: "span.cmp-icon",
    currentSelectWithLanguage: ".language-dropdown-JS:not(.hidden)",
    isFixed: false,
    isContainForceClass: false,
    isBiggerThanScroll: false,
    $containerActive: null,
    $lastTab: null,
    $containerTabs: null,
    $caret: null,
    events: [],
    isLoaded: false,
    closedModalFromTab: false,
  },

  init: function () {
    if (bcl.s.tabs.props.isLoaded) {
      return;
    }

    const baseFind = document;
    const containerTabs = baseFind.getElementsByClassName(bcl.s.tabs.props.containerTabsSelector);
    bcl.u.forEach(containerTabs, bcl.s.tabs.initContainer);
    bcl.s.tabs.disablePopUpSelectLanguageWhenOnlyOneLanguage(baseFind);
    bcl.s.tabs.props.isLoaded = true;
  },

  disablePopUpSelectLanguageWhenOnlyOneLanguage: function (baseFind) {
    const $languageDropDown = baseFind.querySelector(bcl.s.tabs.props.languageDropdownJS);
    if ($languageDropDown) {
      const disabledValue = $languageDropDown.getAttribute(bcl.s.tabs.props.disabled);
      if (disabledValue && disabledValue === bcl.s.tabs.props.disabled) {
        const $spanLanguage = document.querySelectorAll(bcl.s.tabs.props.languageCurrentJS)[0];
        if ($spanLanguage) {
          $spanLanguage.parentNode.style.cursor = "default";
        }

        if (bcl.u.mobile.isMobile()) {
          const $rowSpan = document.querySelectorAll(bcl.s.tabs.props.rowSpan)[0].parentNode;
          if ($rowSpan) {
            $rowSpan.querySelectorAll(bcl.s.tabs.props.spanRowIcon)[1].remove();
          }
        }
      }
    }
  },

  initContainer: function ($containerTabs) {
    const $tabs = $containerTabs.querySelectorAll("[" + bcl.s.tabs.props.containerTabAttr + "]");
    $containerTabs.addEventListener("mousedown", function () {
      bcl.s.tabs.props.clickInContainer = true;
    });

    if (!bcl.u.mobile.isMobile() && bcl.u.containsClass($containerTabs, "mega-menu-JS")) {
      bcl.s.tabs.resizeTabsMegaMenu();
    }

    const form = document.querySelector("." + bcl.s.tabs.props.contentSelector + ".smenu-lang");

    if (form) {
      bcl.s.tabs.props.oldLayout = form.dataset.languageLayout;

      bcl.s.tabs.footerLogicLenguaje(form);
    }

    bcl.u.forEach($tabs, function ($tab) {
      const value = $tab.dataset.containertab;

      if (value) {
        const $container = $containerTabs.getElementsByClassName(value);

        if (!$container?.[0]) {
          return;
        }

        bcl.s.tabs.closeButton($container[0]);

        const eventType = "click";

        // PLASOFT-21696 only open tab o click or enter tab and close tab on focusout
        // if ($tab.nodeName == "INPUT") {
        //   eventType = "focusin";
        // }
        $container[0].addEventListener("focusout", function () {
          setTimeout(() => {
            const target = document.activeElement;
            if (target && !$container[0].contains(target) && target != $tab) {
              if (bcl.modal.props?.$currentElement && $container[0].contains(bcl.modal.props.$currentElement)) {
                return;
              }
              bcl.s.tabs.close($container[0], $tab);
            }
          }, 0);
        });

        if ($tab.dataset.event === "mouseover" && !bcl.u.mobile.isMobile()) {
          $tab.addEventListener("mouseover", function (e) {
            bcl.s.tabs.clearEvent(value);
            if (!bcl.s.tabs.props.events[value] ? true : false) {
              bcl.s.tabs.props.events[value] = window.setTimeout(function () {
                if (bcl.s.tabs.props.events[value] ? true : false) {
                  bcl.s.tabs.eventInTab(e, $containerTabs, $container, $tab);
                }
              }, 200);
            }
          });

          $tab.addEventListener("mouseout", function (e) {
            bcl.s.tabs.clearEvent(value);
            bcl.s.tabs.eventWithTimeout(e, $containerTabs, $container, $tab, value);
          });

          $container[0].addEventListener("mouseover", function () {
            bcl.s.tabs.clearEvent(value);
          });

          $container[0].addEventListener("mouseout", function (e) {
            bcl.s.tabs.clearEvent(value);
            bcl.s.tabs.eventWithTimeout(e, $containerTabs, $container, $tab, value);
          });
        } else {
          $tab.addEventListener(eventType, function (e) {
            if (!bcl.u.containsClass($tab, "block-tab-event") && !bcl.u.containsClass($tab, "tab-blocked-currency-JS")) {
              bcl.s.tabs.eventInTab(e, $containerTabs, $container, $tab);
            }
          });
        }
      }
    });
  },

  resizeTabsMegaMenu: function () {
    const $container = document.querySelector(".mega-menu-JS");

    if (!$container) {
      return;
    }

    const containerWidth = $container.offsetWidth,
      $tabs = $container.querySelectorAll("[" + bcl.s.tabs.props.containerTabAttr + "]");

    let totalWidthTabs = 0;
    const bigger = [];
    bcl.u.forEach($tabs, function ($tab) {
      bigger.push({
        node: $tab.parentNode,
        value: $tab.parentNode.offsetWidth,
      });
      totalWidthTabs += $tab.parentNode.offsetWidth;
    });

    bigger.sort(function (a, b) {
      if (a.value > b.value) {
        return -1;
      } else if (b.value > a.value) {
        return 1;
      } else {
        return 0;
      }
    });
    if (containerWidth < totalWidthTabs) {
      bigger[0].node.style.maxWidth = (100 / bigger.length).toString() + "%";
      bcl.s.tabs.resizeTabsMegaMenu();
    }
  },

  closeButton: function ($container) {
    const parent = $container.closest(".c-popover");
    const closeButton = parent?.querySelector("." + bcl.s.tabs.props.classClose);
    if (closeButton) {
      closeButton.addEventListener("click", function () {
        bcl.s.tabs.closeActive();
      });
    }
  },

  eventWithTimeout: function (e, $containerTabs, $container, $tab, value) {
    bcl.s.tabs.props.events[value + "-c"] = window.setTimeout(function () {
      if (bcl.s.tabs.props.events[value] ? true : false) {
        bcl.s.tabs.close($container[0], $tab);
      }

      bcl.s.tabs.clearEvent(value);
    }, 200);
  },

  clearEvent: function () {
    for (const key in bcl.s.tabs.props.events) {
      window.clearTimeout(bcl.s.tabs.props.events[key]);
      delete bcl.s.tabs.props.events[key];
      window.clearTimeout(bcl.s.tabs.props.events[key + "-c"]);
      delete bcl.s.tabs.props.events[key + "-c"];
    }
  },

  eventInTab: function (e, $containerTabs, $container, $tab) {
    e.stopPropagation();

    let closeActive = false;
    if (bcl.s.tabs.props.$containerTabs && bcl.s.tabs.props.$containerTabs != $containerTabs) {
      closeActive = true;
    }

    bcl.s.tabs.props.$containerTabs = $containerTabs;

    let i = $container.length - 1,
      index = 0;

    if (i > 0) {
      for (i; i > -1; i--) {
        if (bcl.u.containsClass($container[i], bcl.s.tabs.props.classItemMultiple)) {
          index = i;
          break;
        }
      }
    }

    if ($tab.dataset.nottouch && bcl.u.mobile.isMobile()) {
      document.activeElement.blur();
    }

    let executeClick = true;

    if ($containerTabs.dataset.initonopen) {
      const initonopen = $containerTabs.dataset.initonopen;
      delete $containerTabs.dataset.initonopen;
      executeClick = bcl.u.executeFunctionByName(initonopen, window);
    }

    if ($tab.dataset.callbackclick) {
      const object = { $containerTabs: $containerTabs, $container: $container, $tab: $tab };
      executeClick = bcl.u.executeFunctionByName($tab.dataset.callbackclick, window, bcl.s.tabs.props.$containerActive, object);
    }

    if (executeClick) {
      if (closeActive) {
        bcl.s.tabs.closeActive();
      }

      bcl.s.tabs.click($container[index], $tab);
    }

    window.dispatchEvent(new CustomEvent("cmp-image-global-redraw", { detail: this.modalBox }));
  },

  click: function ($container, $tab) {
    bcl.s.tabs.updateData($container, $tab);
    const closeButton = $container.querySelectorAll("." + bcl.s.tabs.props.classClose);
    if ($tab.parentNode.closest("div").dataset.languageLayout && bcl.u.mobile.isMobile()) {
      window.dispatchEvent(new CustomEvent("click-footer", { detail: $tab.parentNode.closest("div").dataset.languageLayout }));
      return;
    }

    if (!bcl.s.tabs.processOpen($container, $tab) && !bcl.u.containsClass($tab, bcl.s.tabs.props.classNoClose)) {
      bcl.s.tabs.close($container, $tab);

      return;
    }

    if (closeButton?.[0]) {
      bcl.s.tabs.addEventButton($container, $tab, closeButton[0]);
    }

    bcl.s.tabs.cleanAndSetStylePopFbDialog($container);

    bcl.s.tabs.props.$containerActive = $container;
    bcl.s.tabs.props.$lastTab = $tab;
  },

  addEventButton: function ($container, $tab, $closeButton) {
    $closeButton.addEventListener(
      "click",
      function () {
        bcl.s.tabs.close($container, $tab);
      },
      { once: true },
    );
  },

  update: function ($container, $tab) {
    bcl.s.tabs.open($container, $tab);
  },

  updateData: function () {
    bcl.s.tabs.props.$caret = bcl.s.tabs.props.$containerTabs.querySelector("." + bcl.s.tabs.props.caretSelector);
  },

  processOpen: function ($container, $tab) {
    bcl.s.tooltip.closeActive();

    if (bcl.s.tabs.props.$containerActive == null) {
      window.addEventListener("click", bcl.s.tabs.clickWindow);

      return bcl.s.tabs.open($container, $tab);
    }

    if ($container != bcl.s.tabs.props.$containerActive) {
      return bcl.s.tabs.openWithContainerDistinct($container, $tab);
    }

    if ($tab != bcl.s.tabs.props.$lastTab) {
      return bcl.s.tabs.openWithContainerActiveAndTabDistinct($container, $tab);
    }

    return false;
  },

  checkIfOnlyOneLanguage: function ($container) {
    let $disabled = false;
    const $languageDropDown = $container.querySelector(bcl.s.tabs.props.currentSelectWithLanguage);
    if ($languageDropDown) {
      const disabledValue = $languageDropDown.getAttribute("disabled");
      if (disabledValue && disabledValue === "disabled") {
        $disabled = true;
      }
    }
    return $disabled;
  },

  cleanAndSetStylePopFbDialog: function ($container) {
    if (!$container) {
      return;
    }
    bcl.s.tabs.cleanStylePopFbDialogInformation($container);

    if (bcl.u.containsClass(document.body, "activePaginated")) {
      bcl.s.tabs.setStylePopFb($container);
    }
  },

  setStylePopFb: function ($container) {
    const totalHeight = $container.getBoundingClientRect().top + $container.offsetHeight;

    if (totalHeight > window.innerHeight) {
      $container.style.overflow = "auto";
      $container.style.height = "calc(100vh - 196px)";
    }
  },

  cleanStylePopFbDialogInformation: function ($container) {
    $container.style.overflow = "";
    $container.style.height = "";
  },

  clickWindow: function (e) {
    if (bcl.s.tabs.props.clickInContainer) {
      bcl.s.tabs.props.clickInContainer = false;
      return;
    }

    bcl.s.tabs.closeActive(bcl.s.currency.props.isRelativePriceRequest, e.isTrusted);
  },

  closeActive: function (isRelatedPriceRequest, isHumanClick) {
    if (!bcl.s.tabs.props.$containerActive || !bcl.s.tabs.props.$lastTab) {
      return;
    }
    // PLASOFT-21696 modal from tab click
    if (bcl.modal.props.$currentElement != null && bcl.s.tabs.props.$containerActive.contains(bcl.modal.props.$currentElement)) {
      return;
    }
    // PLASOFT-21696 modal from tab close
    if (bcl.s.tabs.props.closedModalFromTab) {
      bcl.s.tabs.props.closedModalFromTab = false;
      return;
    }
    bcl.s.tabs.close(bcl.s.tabs.props.$containerActive, bcl.s.tabs.props.$lastTab, isRelatedPriceRequest, isHumanClick);
  },

  openWithContainerDistinct: function ($container, $tab) {
    bcl.s.tabs.toggleClassActive(bcl.s.tabs.props.$containerActive, false);

    bcl.s.tabs.callbackContainer(bcl.s.tabs.props.$containerActive, $tab, "callbackclose");

    bcl.s.tabs.toggleClassActive(bcl.s.tabs.props.$lastTab, false);

    return bcl.s.tabs.open($container, $tab);
  },

  toggleClassActive: function ($container, boolean) {
    bcl.u.removeClass($container, "hidden");

    if (boolean) {
      bcl.u.addClass($container, bcl.s.tabs.props.classActive);
      bcl.u.removeClass($container, bcl.s.tabs.props.classHidden);

      if ($container.classList.contains("js-item-myBarcelo-popover")) {
        const barceloMenuLogin = $container.querySelector(".js-myb-menu-login-v2");
        const barceloMenuLogged = $container.querySelector(".js-myb-menu-v2");

        if (barceloMenuLogin || barceloMenuLogged) {
          $container.classList.add("mod--barceloMenuV2");
          $container.parentElement.classList.add("mod--aside-barceloMenuV2");
        }
      }
      return;
    }
    bcl.u.removeClass($container, bcl.s.tabs.props.classActive);
    bcl.u.addClass($container, bcl.s.tabs.props.classHidden);

    if ($container) {
      this.clearCurrencyInput($container);
    }
  },
  clearCurrencyInput: function ($container) {
    const currencySelect = $container.querySelector(".currency-select-JS");
    if (!currencySelect) {
      return;
    }

    const currencyInput = currencySelect.querySelector("#currency-filter");
    if (!currencyInput) {
      return;
    }
    currencyInput.value = "";

    const options = currencySelect.querySelectorAll("label");
    if (options) {
      options.forEach((opt) => {
        opt.classList?.remove("hidden");
      });
    }
  },
  openWithContainerActiveAndTabDistinct: function ($container, $tab) {
    bcl.s.tabs.toggleClassActive($tab, true);
    bcl.s.tabs.toggleClassActive(bcl.s.tabs.props.$lastTab, false);

    bcl.s.tabs.addStyle($container, $tab);
    return true;
  },

  open: function ($container, $tab) {
    if (bcl.u.containsClass($container, "content-JS-0") && bcl.s.tabs.checkIfOnlyOneLanguage($container)) {
      return false;
    }

    bcl.u.removeClass($container.parentNode, "mod--hide");
    bcl.s.tabs.changeClass($container, $tab, true);

    bcl.s.tabs.addStyle($container, $tab);
    if ($tab) {
      $tab.setAttribute("aria-expanded", "true");
    }
    if (!bcl.u.mobile.isMobile()) {
      const position = $container.parentElement.getBoundingClientRect();
      if (position.bottom > window.innerHeight) {
        const offset = $container.parentElement.dataset.scrolltooffset ? parseInt($container.parentElement.dataset.scrolltooffset) : 0;
        bcl.u.goToWithScroll({
          top: document.documentElement.scrollTop + (position.bottom - window.innerHeight) + offset,
        });
      }
    }

    bcl.s.tabs.callbackContainer($container, $tab, "callbackopen");
    $container.focus();
    return true;
  },

  close: function ($container, $tab, isRelatedPriceRequest, isHumanClick) {
    if (!isRelatedPriceRequest || isHumanClick) {
      window.removeEventListener("click", bcl.s.tabs.clickWindow);
      bcl.u.mobile.isMobile() && bcl.c.fastbooking.destination?.toggleRemoveScrollingClass("removeClass");

      bcl.s.tabs.props.$containerActive = null;
      bcl.s.tabs.props.$lastTab = null;

      bcl.u.addClass($container.parentNode, "mod--hide");
      bcl.s.tabs.changeClass($container, $tab, false);
      if ($tab) {
        $tab.setAttribute("aria-expanded", "false");
      }
      $container.parentNode.style.left = null;

      if (bcl.s.tabs.props.$caret) {
        bcl.s.tabs.props.$caret.style.left = null;
      }

      bcl.s.tabs.callbackContainer($container, $tab, "callbackclose");
      $tab.focus();
    }
  },

  callbackContainer: function ($container, $tab, type) {
    if ($container.dataset[type]) {
      bcl.u.executeFunctionByName($container.dataset[type], window, $container, $tab);
    }
  },

  changeClass: function ($container, $tab, method) {
    bcl.s.tabs.toggleClassActive($tab, method);
    bcl.s.tabs.toggleClassActive($container, method);
    bcl.s.tabs.toggleClassActive($container.parentNode, method);
  },

  addStyle: function ($container, $tab) {
    bcl.s.tabs.addStyleLeft($container, $tab);
    bcl.s.tabs.addStyleTop($container, $tab);
  },

  addStyleLeft: function ($container, $tab) {
    const mobile = bcl.u.mobile.isMobile();

    if (!mobile || !bcl.s.tabs.props.$containerTabs.dataset.onlydesktop) {
      let left = 0,
        prop = "width";

      if (mobile && $container.dataset.widthmobile) {
        prop = "widthmobile";
      }

      if ($container.dataset.caretmod) {
        bcl.u.addClass(bcl.s.tabs.props.$caret, "hidden");
        bcl.u.addClass(bcl.s.tabs.props.$caret, "hide-caret");
      }

      bcl.s.tabs.props.offsetTab = bcl.u.getOffset($tab);

      if ($container.dataset[prop] == "full") {
        $container.parentNode.style.left = null;
      } else if ($container.dataset[prop] == "center") {
        left = bcl.s.tabs.processCenter($container, $tab);
      } else if ($container.dataset[prop] == "centerTab") {
        left = bcl.s.tabs.processCenterTab($container, $tab, mobile);
      } else {
        left = bcl.s.tabs.processLeft($container, $tab, mobile);
      }

      if (!bcl.s.tabs.props.$caret) {
        return;
      }

      bcl.s.tabs.props.$caret.style.left = bcl.s.tabs.calcCaretLeft($tab, left, mobile) + "px";

      if ($container.dataset.caretmod) {
        setTimeout(function () {
          bcl.u.removeClass(bcl.s.tabs.props.$caret, "hidden");
        }, 350);
        setTimeout(function () {
          bcl.u.removeClass(bcl.s.tabs.props.$caret, "hide-caret");
        }, 400);
      }
    }
  },

  addStyleTop: function ($container, $tab) {
    const mobile = bcl.u.mobile.isMobile();

    if (bcl.s.tabs.props.$containerTabs.dataset.top || (mobile && bcl.s.tabs.props.$containerTabs.dataset.mobiletop)) {
      let top = 0;
      const offsetTab = bcl.u.getOffset($tab.parentNode),
        offsetTabAncestor = bcl.u.getOffset(bcl.s.tabs.props.$containerTabs);

      top = offsetTab.top - offsetTabAncestor.top + $tab.offsetHeight + 5;

      $container.parentNode.style.top = top + "px";

      bcl.s.tabs.initPropsProcessTop($container);
      if (!mobile || bcl.u.containsClass($container, "content-JS-favoriteShare")) {
        bcl.s.tabs.props.isBiggerThanScroll = $tab.getBoundingClientRect().top + $container.offsetHeight >= (window.scrollY || document.documentElement.scrollTop);
        bcl.s.tabs.props.isContainForceClass = bcl.u.containsClass($container, "forceUp-JS");

        if (bcl.s.tabs.isShowUpperPop($container, $tab)) {
          bcl.s.tabs.processTop($container, $tab);
          bcl.s.tabs.moveUpper($container);
        } else {
          bcl.s.tabs.showCaretUp($container, false);
        }
      }
    }
  },

  centerMegamenu: function ($container, $header) {
    $container.parentNode.style.left = "";

    const headerBoundLeft = $header.getBoundingClientRect().left,
      containerBoundLeft = $container.getBoundingClientRect().left;
    let leftValue = "";

    if (headerBoundLeft > containerBoundLeft) {
      leftValue = containerBoundLeft - headerBoundLeft;
    } else if (headerBoundLeft < containerBoundLeft) {
      leftValue = headerBoundLeft - containerBoundLeft;
    }

    return leftValue;
  },

  processCenter: function ($container) {
    const width = bcl.s.tabs.props.$containerTabs.offsetWidth,
      containerWidth = $container.offsetWidth,
      $header = bcl.s.tabs.props.$containerTabs.closest(".c-header");

    let value = (width - containerWidth) / 2;

    if (bcl.s.tabs.props.$containerTabs.getBoundingClientRect().left + value < 0) {
      value -= (bcl.s.tabs.props.$containerTabs.getBoundingClientRect().left + value) / 2;
    }

    value = $header ? bcl.s.tabs.centerMegamenu($container, $header) : value;

    $container.parentNode.style.left = value + "px";

    return value;
  },

  processCenterTab: function ($container, $tab, isMobile) {
    const left = bcl.s.tabs.calcContainerLeft($tab),
      containerWidth = $container.offsetWidth;

    let value = left + $tab.offsetWidth / 2 - containerWidth / 2;

    if (bcl.s.tabs.props.offsetTab.left + containerWidth / 1.5 >= window.innerWidth) {
      value = bcl.s.tabs.processLeft($container, $tab, isMobile);
    }
    $container.parentNode.style.left = value + "px";

    return value;
  },

  processLeft: function ($container, $tab, isMobile) {
    let left = bcl.s.tabs.calcContainerLeft($tab);
    const containerWidth = $container.offsetWidth,
      widthToAdjustInMobile = ($tab.getBoundingClientRect().left - window.innerWidth) / 2;

    if (bcl.s.tabs.props.offsetTab.left + containerWidth >= window.innerWidth) {
      left = left - containerWidth + $tab.offsetWidth;
    }

    if (isMobile && !bcl.u.mobile.isTablet()) {
      left = left - widthToAdjustInMobile;
    }

    $container.parentNode.style.left = left + "px";

    return left;
  },

  calcContainerLeft: function () {
    const value = bcl.s.tabs.props.offsetTab.left,
      $containerTabs = bcl.s.tabs.props.$containerTabs.querySelector("." + bcl.s.tabs.props.tabsSelector);

    return value - bcl.u.getOffset($containerTabs).left;
  },

  calcCaretLeft: function ($tab, containerLeft, isMobile) {
    const widthNotMobile = bcl.s.tabs.calcContainerLeft($tab) - containerLeft + $tab.offsetWidth / 2,
      widthInMobile = bcl.s.tabs.calcContainerLeft($tab) - containerLeft + $tab.offsetWidth;

    return isMobile ? widthInMobile : widthNotMobile;
  },

  moveUpper: function ($container) {
    bcl.u.addClass($container.parentNode, bcl.s.tabs.props.classShowUpper);
  },

  processTop: function ($container, $tab) {
    let top = bcl.s.tabs.calcContainerTop($tab);
    const containerHeight = $container.offsetHeight;

    if (bcl.s.tabs.isShowUpperPop($container, $tab)) {
      top = top - containerHeight - $tab.offsetHeight / 2;
    }

    bcl.s.tabs.showCaretUp($container, true);

    $container.parentNode.style.top = top + "px";

    return top;
  },

  initPropsProcessTop: function ($container) {
    const parentSticky = $container.closest(".sticky-JS");
    if (parentSticky) {
      bcl.s.tabs.props.isFixed = window.getComputedStyle(parentSticky, null).getPropertyValue("position") === "fixed";
    }

    bcl.s.tabs.props.isContainForceClass = false;
    bcl.s.tabs.props.isBiggerThanScroll = false;
  },

  isShowUpperPop: function ($container, $tab) {
    const containerHeight = $container.offsetHeight;
    return (bcl.s.tabs.props.offsetTab.top + containerHeight >= window.innerHeight || bcl.s.tabs.props.isContainForceClass) && (bcl.s.tabs.props.isBiggerThanScroll || bcl.s.tabs.props.isFixed || bcl.s.tabs.isBiggerOrEqualThanMaxScroll($container, $tab));
  },

  isBiggerOrEqualThanMaxScroll: function ($container, $tab) {
    const scrollMaxY = window.scrollMaxY || document.documentElement.scrollHeight - document.documentElement.clientHeight;
    return (window.scrollY || document.documentElement.scrollTop) + $tab.getBoundingClientRect().top + $container.offsetHeight >= scrollMaxY;
  },

  toggleShowUp: function ($containerContent, show) {
    if ($containerContent && show && (bcl.s.tabs.props.isBiggerThanScroll || bcl.s.tabs.props.isFixed)) {
      bcl.u.addClass($containerContent, "mod--showUp");
    } else if ($containerContent) {
      bcl.u.removeClass($containerContent, "mod--showUp");
    }
  },

  showCaretUp: function ($container, show) {
    const $parent = $container.closest(bcl.s.tabs.props.containerCountryLangClass) || $container.closest(".container-tabs-JS");
    if ($parent) {
      const $containerContent = $parent.querySelector("." + bcl.s.tabs.props.containerContentSelector);
      bcl.s.tabs.toggleShowUp($containerContent, show);
    }
  },

  calcContainerTop: function () {
    const value = bcl.s.tabs.props.offsetTab.top,
      $containerTabs = bcl.s.tabs.props.$containerTabs.querySelector("." + bcl.s.tabs.props.tabsSelector);

    return value - bcl.u.getOffset($containerTabs).top;
  },

  calcCaretTop: function ($tab, containerTop) {
    return bcl.s.tabs.calcContainerTop($tab) - containerTop + $tab.offsetHeight / 2;
  },

  resize: function () {
    if (bcl.s.tabs.props.$containerActive && bcl.s.tabs.props.$lastTab) {
      bcl.s.tabs.update(bcl.s.tabs.props.$containerActive, bcl.s.tabs.props.$lastTab);
    }
  },

  openNext: function (count) {
    if (!count) {
      count = 1;
    }

    if (bcl.s.tabs.props.$lastTab && bcl.s.tabs.props.$containerActive && bcl.s.tabs.props.$containerTabs) {
      let valueAttr = bcl.s.tabs.props.$lastTab.dataset.containertab;
      const regex = /(\w+-JS-)(\d)+/,
        int = valueAttr.replace(regex, "$2"),
        valueNewTab = valueAttr.replace(regex, "$1" + (parseInt(int) + count)),
        $tab = bcl.s.tabs.props.$containerTabs.querySelector("[" + bcl.s.tabs.props.containerTabAttr + '="' + valueNewTab + '"]');

      if (!$tab) {
        return;
      }

      valueAttr = $tab.dataset.containertab;

      const $container = bcl.s.tabs.props.$containerTabs.getElementsByClassName(valueAttr);

      if (!$container?.[0]) {
        return;
      }

      bcl.s.tabs.click($container[0], $tab);
    }
  },

  openPrev: function () {
    bcl.s.tabs.openNext(-1);
  },

  changeContainerWithEqualTab: function (className) {
    if (!bcl.s.tabs.props.$containerActive || !bcl.s.tabs.props.$containerTabs) {
      return;
    }

    bcl.s.tabs.toggleClassActive(bcl.s.tabs.props.$containerActive, false);

    bcl.u.removeClass(bcl.s.tabs.props.$containerActive, bcl.s.tabs.props.classItemMultiple);

    const $container = bcl.s.tabs.props.$containerTabs.getElementsByClassName(className);

    if (!$container?.[0]) {
      return;
    }

    bcl.s.tabs.props.$containerActive = $container[0];
    bcl.u.addClass(bcl.s.tabs.props.$containerActive, bcl.s.tabs.props.classItemMultiple);
    bcl.s.tabs.toggleClassActive(bcl.s.tabs.props.$containerActive, true);
  },

  footerLogicLenguaje: function (form) {
    const $selectorLenguage = document.querySelector(".c-megamenu__menu-utils-item-anchor.language-selection");
    const $hambur = document.querySelector("." + megaMenuCommonProps.classNameMenuBtn);
    if ($selectorLenguage && !$selectorLenguage.dataset.listener) {
      $selectorLenguage.dataset.listener = true;
      window.addEventListener("click-footer", function (e) {
        e.preventDefault();
        $hambur.click();
        setTimeout(function () {
          $selectorLenguage.click();
          if (e.detail == "lang") {
            form.dataset.languageLayout = e.detail;
          }
          if (e.detail == "market") {
            form.dataset.languageLayout = e.detail;
          }
          bcl.c.header.language.hideIfNeeded(form);
          bcl.c.header.language.showIfNeeded(form);
        }, 1);
      });
    }
    window.addEventListener("after-click-footer", function (e) {
      e.preventDefault();
      const form = document.querySelector("." + bcl.s.tabs.props.contentSelector + ".smenu-lang");
      form.dataset.languageLayout = bcl.s.tabs.props.oldLayout;
      bcl.c.header.language.showIfNeeded(form);
      bcl.c.header.language.hideIfNeeded(form);
    });
  },
};

bcl.u.docReady(bcl.s.tabs.init);
bcl.u.resize(bcl.s.tabs.resize);
